<template>
  <div class="py-sm-y" >
    <div :class="productsContainerClasses">
        <Product
          v-for="(product, index) in products"
          :product="product"
          :key="product.slug"
          :gtm-item-list-name="gtmItemListName"
          :index="index + 1"
          :hide-header="hideProductHeader"
          :owner="owner"
          @onClick="onClickProduct"
        />
    </div>
  </div>
</template>

<script>

import Product from "~/components/Product.vue"
export default {
  components: {Product},
  props: {
    rowId: {
      type: Number,
      default: null
    },
    products: {
      type: Array,
      required: true
    },
    gtmItemListName: String ,
    owner: {
      type: Boolean,
      default: false
    },
    hideProductHeader: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClickProduct() {
      if (this.rowId) {
        this.$emit('onClickProduct', this.rowId)
      }
    }
  },
  computed: {
    productsContainerClasses() {
      return {
        'grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-14 items-stretch': true
      }
    },
  }
}
</script>
