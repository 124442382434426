<template>
  <div class="flex content">
    <ul class="col-span-12 flex w-full list-none py-6 font-heading text-black-light">
      <li class="flex items-center"
          v-for="(item, index) in crumbs"
          :key="index">
        <Arrow v-if="index > 0" class="w-2 h-full mx-3 fill-current"/>
        <nuxt-link class="text-12 leading-none capitalize sm:transform-colors sm:hover:text-primary"
                   exact-active-class="text-primary font-bold pointer-events-none"
                   :to="item.path"
          @click.native="pushNavigatedToEvent(item)"
        >
          {{ getFormattedItemLabel(item) }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Arrow from "~/assets/svg/arrow.svg?inline";
import { navigatedToParams, NAVIGATION_LOCATIONS } from "assets/js/gtm-events";

export default {
  components: {Arrow},
  props: {
    forcePath: Array
  },
  computed: {
    crumbs: ({$router, $i18n, $route,$nuxt, $props}) => {

      let levels = [];
      if ($props.forcePath) {
        levels = [
          {
            i18n: $i18n.t('breadcrumb.home'),
            path: '/' + $i18n.locale
          },
          ...$props.forcePath.map(item => ({
            i18n: item.name,
            path: item.url.indexOf('/') === 0 ? item.url : '/' + $i18n.locale +  '/' + item.url,
            id: item.id,
          }))];

      } else {
        const path = $route.path.replace(/\/$/, '');
        let temp = ''
        const split = path.split('/');
        for (let i = 0; i < split.length; i++) {
          const route = split[i];
          temp += (i > 0 ? '/' : '') + route;
          const match = $router.matcher.match(temp);
          if (match) {
            let name = match.path.split('/').pop();
            if (name === '') {
              name = $i18n.t('site.home');
            }
            if (name !== $i18n.locale) {
              levels.push({
                i18n: name,
                path: match.path
              });
            }
          }
        }
      }
      return levels;
    }
  },
  methods: {
    getFormattedItemLabel(item) {
      return item && item.i18n ? item.i18n.toLowerCase() : '';
    },
    async pushNavigatedToEvent(breadcrumbItem) {
      this.$gtm.push(navigatedToParams(
        this.getFormattedItemLabel(breadcrumbItem),
        NAVIGATION_LOCATIONS.breadcrumb,
        breadcrumbItem.id ? await this.$store.dispatch('nav/searchByID', breadcrumbItem.id) : await this.$store.dispatch('nav/searchByUrl', breadcrumbItem.url),
      ));
    },
  }
}
</script>

<style lang="scss" scoped>
.nuxt-link-exact-active {
  @apply text-primary font-bold;
}
</style>
