<template>
  <button type="button"
          @click="onClick"
          class="rounded-full text-14 mx-2 border border-solid  flex justify-center items-center bg-primary border-primary font-bold transition-all text-white shadow active:scale-[0.98]"
          :class="{'w-[25rem] px-5 py-2': !quiet, 'p-2 w-16': quiet}">
    <Icon icon="bell" class="w-8 h-8 flex-shrink-0" :class="{'mr-4': !quiet}" /> <span class="overflow-hidden transition-all truncate" :class="{'w-0 h-0': quiet, 'w-auto': !quiet}">{{ $t('search.save.title') }}</span>
  </button>
</template>

<script>
import Icon from "@/components/global/Icon.vue";
export default {
  name: "SaveSearchButton",
  components: {Icon},
  props: {
    quiet: Boolean,
    data: Object,
  },
  methods: {
    async onClick() {
      if (this.$auth.loggedIn) {
        this.$nextTick(() => {
          this.$store.dispatch('openForm', {
            width: 'regular',
            component: 'saveSearch',
            type: 'popinV2',
            data: {
              query: this.data,
              path: this.$route.fullPath
            }
          })
        })
      } else {
        if (this.$store?.state?.auth) {
          this.$store.state.auth.redirect = this.$route.fullPath
        }
        await this.$store.dispatch('openForm', {
          width: 'regular',
          component: 'login',
          type: 'popinV2',
          callback: this.onClick.bind(this),
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
