<template>
  <Badge @click.native.stop="onClick"
         :icon="active ? 'big_check' : null"
         :label="label"
         icon-color="text-white"
         border
         large
         py="py-2"
         class="mr-3"
         :class="{'font-bold': active}"
         :type="styleType"
         rounded/>
</template>

<script>
import Badge from "@/components/global/Badge.vue"
import {STYLE_TYPES} from "assets/js/constants/constant";

export default {
  components: {Badge},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    infos: {
      type: Object,
      default: null
    },
    list: Array,
    fullList: Array,
    countable: Object,
    type: String,
    mutation: String,
    i18n: String,
    isMultiple: Boolean,
    hasSearch: Boolean,
    hasFooter: {
      type: Boolean,
      default: false
    },
    property: [Number, String],
    max: {
      type: Number,
      default: -1
    },
    min: {
      type: Number,
      default: -1
    }
  },
  mounted() {
    this.$root.$on(`${this._uid}-response`, this.onResponse);
  },
  beforeDestroy() {
    this.$root.$off(`${this._uid}-response`, this.onResponse);
  },
  methods: {
    onResponse(data) {
      this.$emit('response', data);
    },
    onClick() {
      this.$store.commit('selectorPopin/list', this.list);
      this.$store.commit('selectorPopin/fullList', this.fullList);
      this.$store.commit('selectorPopin/mutation', this.mutation);
      this.$store.commit('selectorPopin/property', this.property);
      this.$store.commit('selectorPopin/multiple', this.isMultiple);
      this.$store.commit('selectorPopin/search', this.hasSearch);
      this.$store.commit('selectorPopin/label', this.label);
      this.$store.commit('selectorPopin/type', this.type);
      this.$store.commit('selectorPopin/infos', this.infos);
      this.$store.commit('selectorPopin/min', this.min);
      this.$store.commit('selectorPopin/max', this.max);
      this.$store.commit('selectorPopin/busId', this._uid);
      this.$store.commit('selectorPopin/value', this.value);
      this.$store.commit('selectorPopin/hasFooter', this.hasFooter);
      this.$store.commit('selectorPopin/forceSize', this.$screen.mobile);
      this.$store.commit('selectorPopin/countable', this.countable);
    }
  },
  computed: {
    label() {
      return this.$t(this.i18n);
    },
    styleType() {
      return this.active ? STYLE_TYPES.primary : STYLE_TYPES.lighter
    },
    active() {
      let active = false;
      const list = (this.fullList?.length > 0) ? this.fullList : this.list
      if (this.value?.length && list?.length) {
        let ids = this.value.map(item => item.id);
        let listIds = list[0].children.map(item => item.id);
        active = listIds.some(id => ids.includes(id));
      }
      return active;
    }
  }
};
</script>
