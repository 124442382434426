<template>
  <div
    class="e-page-product e-transition h-[100vh] fixed sm:relative sm:h-auto listing-page bg-white">
    <div class="relative h-full">
      <div class="h-full w-full flex flex-col bg-white">
        <!-- CONTENT TABS -->
        <div ref="container" class="h-full w-full">
          <div class="h-full w-full">
            <List
              ref="list"
              class="h-full"
              v-if="currentPage"
              :per-page="productPerPage"
              :query="infiniteLoaderQuery"
              :more-active="hasMore"
              :per-row="2"
              :start-at="2"
              :current-page="currentPage"
              @onPage="currentPage = $event"
              :initial-products="products"
              :api="'/api/v2/search'"
              :params="apiQueries"
              @onScroll="scrolling = $event > 0"
              :last-page="lastPage"
              @update="onUpdate"
              @refresh="refresh"
            >
              <template #header>
                <HeaderLight class="lg:hidden" :logo-visible="false" :has-back-emitter="true" :on-click-back="back"/>
                <Picture class="z-[2] sm:relative xs:pb-[60px]" :src="brand?.partnerHeaderImage" :sizes="['1440:200']" v-if="brand?.partnerHeaderImage"/>
                <BreadCrumb :forcePath="breadCrumb" v-if="!$device.isMobile" class="hidden sm:flex"/>
                <div class="relative z-[2] flex flex-col w-full bg-white sm:relative sm:content">
                  <div class="xs:hidden mb-8">
                    <h2 class="font-heading font-bold text-22 capitalize"
                        v-html="seo.pageTitle"/>
                    <h1 v-if="seo.pageDesc"
                        class="font-heading text-black-light text-14"
                        v-html="seo.pageDesc"/>
                  </div>
                  <Swiper v-if="breadCrumb.length === 1 && $screen.desktop"
                          :free-mode="true" slides-per-view="auto"
                          :mousewheel="true" :fade="true"
                          :space-between="30" class="border-b border-surface-light">
                    <template v-slot="{readyHandler}">
                      <SwiperSlide class="flex w-auto"
                                   v-for="(child, index) in breadCrumb[breadCrumb.length - 1].children"
                                   @ready="readyHandler"
                                   :key="index + child.id">
                        <nuxt-link :to="localePath({path : `/${child.url}`})"
                                   class="block w-full relative rounded-sm overflow-hidden h-[95px] w-[180px] bg-gradient-to-t from-gradient-light py-1 sm:py-4">
                          <PictureBg class="absolute inset-0"
                                     :src="child.image"
                                     :sizes="['600:800']"/>
                          <div
                            class="z-auto flex h-full relative justify-center items-center">
                            <h2
                              class="font-heading text-white font-bold text-center text-14 capitalize"
                              v-html="child.name"/>
                          </div>
                        </nuxt-link>
                      </SwiperSlide>
                    </template>
                  </Swiper>
                  <div class="flex w-full overflow-hidden items-center py-1 lg:py-4">
                    <div class="flex-grow overflow-hidden">
                      <Swiper :free-mode="true" slides-per-view="auto"
                              :slides-offset-before="10"
                              :slides-offset-after="10"
                              :init-classes="'flex'" :mousewheel="true" :fade="true" class="my-2 items-stretch">
                        <template v-slot="{readyHandler}">
                          <SwiperSlide class="flex w-auto h-auto items-center"
                                       @ready="readyHandler">
                            <Badge :icon="'filter'"
                                   :label="!selectedFilters.length ? $t('Filtrer par'): '&nbsp;'"
                                   @click.native="openFilterPopin"
                                   icon-color="text-white"
                                   border
                                   large
                                   icon-size="w-5 h-5"
                                   py="py-2"
                                   class="mr-3 "
                                   type="black"
                                   rounded/>
                          </SwiperSlide>
                          <SwiperSlide v-if="hasGender" class="flex w-auto flex-col"
                                       @ready="readyHandler">
                            <FilterSelector @response="onFilter"
                                            type="genders"
                                            :is-multiple="true"
                                            :countable="{
                                              countable: filtersList.genders.countable,
                                              type: 'genders',
                                              query: apiQueries
                                            }"
                                            :value="selectedFilters.filter(elt => elt.type === 'genders')"
                                            :list="filtersList.genders.list"
                                            :i18n="$t('filters.genders')"/>
                          </SwiperSlide>
                          <SwiperSlide v-if="filtersList.sizes.list?.length" class="flex w-auto flex-col"
                                       @ready="readyHandler">
                            <FilterSelector @response="onFilter"
                                            type="sizes"
                                            :is-multiple="true"
                                            :countable="{
                                              countable: filtersList.sizes.countable,
                                              type: 'sizes',
                                              query: apiQueries
                                            }"
                                            :value="selectedFilters.filter(elt => elt.type === 'sizes')"
                                            :list="filtersList.sizes.list"
                                            :i18n="$t('filters.sizes')"/>
                          </SwiperSlide>

                          <SwiperSlide v-if="!brand" class="flex w-auto flex-col"
                                       @ready="readyHandler">
                            <FilterSelector
                              @response="onFilter"
                              type="brands"
                              :value="selectedFilters.filter(elt => elt.type === 'brands')"
                              :countable="{
                                countable: filtersList.brands.countable,
                                type: 'brands',
                                query: apiQueries
                              }"
                              :has-search="true"
                              :is-multiple="true"
                              :list="filtersList.brands.list"
                              :full-list="filtersList.brands.fullList"
                              :i18n="$t('filters.brand')"/>
                          </SwiperSlide>
                          <SwiperSlide class="flex w-auto flex-col" @ready="readyHandler">
                            <FilterSelector
                              @response="onFilter"
                              type="colors"
                              :is-multiple="true"
                              :countable="{
                                countable: filtersList.colors.countable,
                                type: 'colors',
                                query: apiQueries
                              }"
                              :value="selectedFilters.filter(elt => elt.type === 'colors')"
                              :list="filtersList.colors.list"
                              :i18n="$t('filters.colors')"/>
                          </SwiperSlide>
                          <SwiperSlide class="flex w-auto flex-col" @ready="readyHandler">
                            <FilterSelector
                              @response="onFilter"
                              type="states"
                              :is-multiple="true"
                              :countable="{
                                countable: filtersList.states.countable,
                                type: 'states',
                                query: apiQueries
                              }"
                              :value="selectedFilters.filter(elt => elt.type === 'states')"
                              :list="filtersList.states.list"
                              :i18n="$t('filters.states')"/>
                          </SwiperSlide>

                          <!-- <SwiperSlide class="flex w-auto flex-col" @ready="readyHandler">
                            <FilterSelector
                              @response="onFilter"
                              type="sellerGenres"
                              :is-multiple="true"
                              :countable="{
                                countable: filtersList.sellerGenres.countable,
                                type: 'sellerGenres',
                                query: apiQueries
                              }"
                              :value="selectedFilters.filter(elt => elt.type === 'seller_genres')"
                              :list="filtersList.sellerGenres.list"
                              :i18n="$t('filters.sellerGenres')"/>
                          </SwiperSlide> -->

                        </template>
                      </Swiper>
                    </div>
                    <button v-if="selectedFilters.length" @click="onReset(false)"
                            class="hidden lg:flex ml-auto mr-6 items-center text-14 flex-shrink-0">
                      {{ $t('catalog.resetFilters') }}
                      <span v-once
                            class="w-8 h-8 bg-secondary rounded-full flex items-center justify-center cursor-pointer ml-4">
                        <Delete class="text-white w-4 block flex-shrink-0"/>
                      </span>
                    </button>
                  </div>

                </div>
              </template>
              <template #before>
                <div v-if="brand?.partnerPushImage" v-html="brand?.partnerPushImage" class="brand-banner relative z-10" />
              </template>
              <template #footer>
                <div class="bottom-footer-safe flex z-10 w-full"
                     :class="{'justify-center': !scrolling, 'justify-end': scrolling, 'fixed': $screen.mobile, 'sticky': !$screen.mobile}">
                  <SaveSearchButton
                    class="transform mb-10"
                    :quiet="scrolling"
                    :data="apiQueries"/>
                </div>

                <div  v-if="!$device.isMobile && seo.seoDesc && seo.seoDesc.length && products.length > 0"
                      class="editor pt-32 text-13 text-surface-medium content" v-html="seo.seoDesc"/>
                <EmptyResults
                          v-if="products.length === 0 && loaded"
                          icon="cart"
                          bottom-icon="arrow-down"
                          :title="$t('search.empty.title')"
                          :description="$t('search.empty.description')"/>
              </template>
            </List>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { refresh } from "@/mixins/screen";
import transition from "@/mixins/transition";
import {debounce} from "underscore";
import FilterSelector from "@/components/filters/FilterSelector"
import BreadCrumb from "@/components/BreadCrumb"
import HeaderLight from "@/components/header/HeaderLight"
import Picture from "@/components/Picture"
import List from "@/components/product/List"
import SaveSearchButton from "@/components/buttons/SaveSearchButton.vue"
import EmptyResults from "@/components/global/EmptyResults.vue"
import Badge from "@/components/global/Badge.vue"
import Swiper from "~/components/Swiper.vue"
import SwiperSlide from "~/components/SwiperSlide.vue"
import PictureBg from "~/components/PictureBg.vue"
import {
  applyFilters, buildAsyncMetas,
  buildBread,
  buildProps, FILTERS_TYPES,
  filtersToQueryObject,
  getApiQueries,
  getSearchSeo, mapCatalog,
  queryToFilters
} from "assets/js/services/search";
import Delete from "~/assets/svg/delete.svg?inline";
import {BRAND_TYPES, DEFAULT_IMG} from "assets/js/constants/constant";
import {
  getEcommerceItems,
  GTM_EVENTS,
  ITEM_LISTS
} from "~/assets/js/gtm-events";
import {slugToID} from "~/assets/js/utils/product";
import {brandSlug} from "assets/js/utils/string";

export default {
  name: 'all',
  auth: false,
  scrollToTop: !process.env.mobileApp,
  gtm: {
    pageView: {
      category: 'catalog',
      title: 'catalog',
    }
  },
  mixins: [refresh, transition],
  meta: {
    scrollToTop: !process.env.mobileApp,
    transition: 'page-to-left',
    depth: 1
  },
  components: {
    PictureBg,
    SwiperSlide,
    Swiper,
    Badge,
    EmptyResults,
    SaveSearchButton,
    List,
    Picture,
    HeaderLight,
    FilterSelector,
    Delete,
    BreadCrumb,
  },
  head() {
    return {
      title: this.seo.metaTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.seo.metaDesc
        },
        {
          hid: 'og:url', property: 'og:url', content: this.url
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.seo.metaTitle
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.seo.metaDesc
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.sharePicture
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content: this.sharePicture
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: this.seo.metaDesc
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: this.url
        }
      ],
    }
  },
  data: () => ({
    lastPage: 0,
    scrolling: false,
    canonical: null,
    hasMore: false,
    products: [],
    apiQueries: {},
    lastRoute: {
      path: null,
      fullPath: null
    },
    infiniteLoaderQuery: {},
    brand: null,
    metas: [],
    catalogs: [],
    breadCrumb: [],
    loaded: false,
    hasGender: false,
    catalogTree: null,
    catalog: null,
    selectedFilters: [],
    filtersList: {
      catalogs: {
        list: [],
      },
      brands: {
        list: [],
        fullList: [],
        countable: false
      },
      colors: {
        list: [],
        countable: true
      },
      states: {
        list: [],
        countable: false
      },
      genders: {
        list: [],
        countable: true
      },
      sizes: {
        list: [],
        countable: true
      },
      sellerGenres: {
        list: [],
        countable: false
      },
      sort_by: {
        custom: true,
        list: [],
      },
    },
    seo: {
      metaTitle: null,
      metaDesc: null
    },
    lastQuery: '{}',
    productPerPage: 40,
    currentPage: null
  }),

  created() {
    if (this.$route?.query?.page) {
      this.currentPage = parseInt(this.$route.query.page)
    } else {
      this.currentPage = 1
    }
  },
  async fetch() {
    this.products = Array(6).fill({images : [DEFAULT_IMG]});

    let slug

    // define brand
    if (this.$route?.params?.slug) {
      slug = brandSlug(this.$route.params.slug, this.$i18n)
      const brand = await this.$api.$get(process.env.apiUrl + '/api/v2/brand/' + slug);

      if (brand && !brand.error) {
        this.brand = brand
      }
    } else {
      slug = this.$route?.params?.pathMatch
      const catalog = await this.$api.$get(process.env.apiUrl + '/api/v2/catalog/' + slug, {params: {seo: 1}});

      if (catalog && !catalog.error) {
        this.catalog = catalog
      }
    }

    if (!this.catalog && !this.isSearch && !this.brand && process.server) {
      this.$nuxt.error({'statusCode': 404, 'message': ''});
    }

    this.breadCrumb = await buildBread(this.$route, this.catalog, this.$i18n, this.brand);

    this.seo = await getSearchSeo(this.brand, this.catalog, this.$i18n, this.$route.query)

    const routeQuery = this.$route.query.q || ''
    this.lastRoute.path = this.$route.path + routeQuery;
    this.lastRoute.fullPath = this.$route.fullPath

    const props = await buildProps(this.$route, this.$i18n, this.$store, this.catalog);
    if (process.client) {
      const metas = await buildAsyncMetas(this.$route, this.$i18n, this.$store, this.config);
      this.assignPropertiesToData({...props, ...metas})
    } else {
      this.assignPropertiesToData(props)
    }

    this.apiQueries = getApiQueries(this.$route.query, this.catalogs, this.brand)

    await this.fetchProducts();
  },
  async activated() {
    console.log('activated')
    const routeQuery = this.$route.query.q || ''
    const isDifferentRoute = this.lastRoute.fullPath !== this.$route.fullPath;
    if (isDifferentRoute && this.lastRoute.fullPath) {
      this.onReset();
      this.seo = await getSearchSeo(this.brand, this.catalog, this.$i18n, this.$route.query)
      const props = await buildProps(this.$route, this.$i18n, this.$store, this.catalog);
      const metas = await buildAsyncMetas(this.$route, this.$i18n, this.$store, this.config);
      this.assignPropertiesToData({...props, ...metas})

      this.breadCrumb = await buildBread(this.$route, this.catalog, this.$i18n, this.brand);
    }
    this.lastRoute.path = this.$route.path + routeQuery;
    this.lastRoute.fullPath = this.$route.fullPath;
  },
  async mounted() {

    await this.$store.dispatch('brands/fetchBrands', {type: BRAND_TYPES.default});
    await this.$store.dispatch('brands/fetchBrands');

    const metas = await buildAsyncMetas(this.$route, this.$i18n, this.$store, this.config);

    this.assignPropertiesToData(metas)

    if (this.isSearch) {
      const catalogsList = mapCatalog(JSON.parse(JSON.stringify(this.nav.filter(level => level.isUniverse === 1))), this.$i18n)
      this.filtersList.catalogs.list = [{
        children: catalogsList
      }]
      this.filtersList.catalogs.reducedList = catalogsList
    }

    const brandsList = this.brands.map(elt => ({
      ...elt,
      type: FILTERS_TYPES.brands
    }))
    this.filtersList.brands.list = [{
      children: this.highlightedBrands?.default?.map(elt => ({
        ...elt,
        type: FILTERS_TYPES.brands
      }))
    }]
    this.filtersList.brands.fullList = [{
      children: brandsList
    }]
    this.filtersList.brands.reducedList = brandsList

    const colorsList = this.config.colors.map(elt => ({
      ...elt,
      type: FILTERS_TYPES.colors
    }))
    this.filtersList.colors.list = [{
      children: colorsList
    }]
    this.filtersList.colors.reducedList = colorsList

    const statesList = this.config.states.map(elt => ({
      ...elt,
      type: FILTERS_TYPES.states
    }))
    this.filtersList.states.list = [{
      children: statesList
    }]
    this.filtersList.states.reducedList = statesList

    // gender
    const gendersList = this.config.gendersProducts.map(elt => ({
      ...elt,
      type: FILTERS_TYPES.genders
    }))
    this.filtersList.genders.list = [{
      children: gendersList
    }]
    this.filtersList.genders.reducedList = gendersList

    // sellerGenres
    const sellerGenresList = this.config.sellerGenres.map(elt => ({
      ...elt,
      type: FILTERS_TYPES.sellerGenres
    }))
    this.filtersList.sellerGenres.list = [{
      children: sellerGenresList
    }]
    this.filtersList.sellerGenres.reducedList = sellerGenresList

    // sizes
    this.filtersList.sizes.reducedList = this.config.sizes.reduce((old, next) => {
      return [...old, ...next.children.map(child => ({
        ...child,
        type: FILTERS_TYPES.sizes
      }))];
    }, [])

    this.filtersList.sort_by.reducedList = this.filtersList.sort_by.list = [
      {
        name: this.$t('filters.sortBy.recent'),
        id: 'field=updated_at&direction=desc'
      },
      {
        name: this.$t('filters.sortBy.priceAsc'),
        id: 'field=price&direction=asc'
      },
      {
        name: this.$t('filters.sortBy.priceDesc'),
        id: 'field=price&direction=desc'
      },
    ]
    this.selectedFilters = queryToFilters(this.$route.query, this.config, this.filtersList);
  },
  watch: {
    selectedFilters(newFilters, oldFilters) {
      const queryParams = filtersToQueryObject(newFilters, this.$route.query, this.$route.name)

      const cleanPath = this.$route.path;

      const queryKeys = Object.keys(queryParams)
      if(oldFilters.length > 0 || (queryKeys.length > 0 && !(queryKeys.length === 1 && queryKeys[0] === 'q'))) {

        this.$router.replace({
          path: cleanPath,
          query: queryParams
        }).catch(() => {
        });
      }
    },
    catalogTree(value) {
      if (value?.length) {
        const currentCatalog = JSON.parse(JSON.stringify(value[value.length - 1]))
        if (currentCatalog.isUniverse === 1 && currentCatalog.children?.length) {
          const catalogsList = mapCatalog([...currentCatalog.children], this.$i18n)

          this.filtersList.catalogs.list = [{
            children: catalogsList
          }]
          this.filtersList.catalogs.reducedList = catalogsList

        }
      }
    },

    async $route(to, from) {
      const isToSearchOrBrand = to.name.indexOf('all___') === 0 || to.name.indexOf('brand-slug___') === 0
      const isFromProduct = from.name.indexOf('product-slug___') >= 0
      const isToPopin = to.query.popin
      const isActive = !this._inactive

      if (isToSearchOrBrand && isActive && !isToPopin && !isFromProduct) {
        let {page, ...query} = this.$route.query
        const hasFiltersChanged = this.lastQuery !== JSON.stringify(query)
        // we reload only if a filter changed
        if (hasFiltersChanged) {
          this.loadProducts();
          this.lastQuery = JSON.stringify(query);
        }

        const routeQuery = this.$route.query.q || ''

        const hasRouteChanged = this.lastRoute.path !== this.$route.path + routeQuery
        if (hasRouteChanged) {
          this.onReset();
          this.seo = await getSearchSeo(this.brand, this.catalog, this.$i18n, this.$route.query)
          const props = await buildProps(this.$route, this.$i18n, this.$store, this.catalog);
          const metas = await buildAsyncMetas(this.$route, this.$i18n, this.$store, this.config);
          this.assignPropertiesToData({...props, ...metas})

          this.breadCrumb = await buildBread(this.$route, this.catalog, this.$i18n, this.brand);
        }

        if (to.path + routeQuery === from.path + routeQuery) {
          this.lastRoute.path = this.$route.path + routeQuery;
          this.lastRoute.fullPath = this.$route.fullPath;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      config: 'config/getConfig',
      nav: 'nav/getNav',
      brands: 'brands/getBrands',
      highlightedBrands: 'brands/getHighLightedBrands',
    }),
    url() {
      return `${process.env.baseUrl}${this.canonical ? ('/fr/' + this.canonical) : this.$route.path}`;
    },
    isSearch() {
      return this.$route.params.pathMatch === 'equipement';
    },
  },
  methods: {
    back() {
      this.$router.back()
    },
    openFilterPopin() {
      this.$store.dispatch('openForm', {
        type: 'popinV2',
        component: 'filterSearch',
        callback: (filters) => this.selectedFilters = filters,
        data: {
          filtersList: this.filtersList,
          selectedFilters: this.selectedFilters,
          metasList: this.metas,
          catalogs: this.catalogs
        }
      })
    },
    assignPropertiesToData(properties) {
      const {sizes, ...props} = properties
      this.filtersList.sizes.list = sizes
      this.filtersList.sizes.list = sizes

      Object.keys(props).forEach(key => this[key] = props[key]);
    },

    async onFilter(data) {
      this.currentPage = 1
      if (this.$screen.mobile) {
        this.nextPage = 2
        this.$refs.list.$refs.scroller.scrollToItem(0)
      }
      this.selectedFilters = await applyFilters(this.selectedFilters, data, this.$gtm, this.$store)
    },

    async pushViewItemListEvent(products, from) {

      let itemIndex = from;
      const formattedProducts = [];
      const {name: itemListName, id: itemListId} = ITEM_LISTS.catalog;

      for (let i = 0; i < products.length; i++) {
        const currentProduct = products[i];
        const categories = await this.$store.dispatch('nav/searchByID', currentProduct.catalogId);
        formattedProducts.push({
          id: slugToID(currentProduct.slug),
          name: currentProduct.name,
          brand: currentProduct.brand.name,
          categories,
          price: currentProduct.price,
          quantity: 1,
          itemState: currentProduct.stateId,
          itemListName: ITEM_LISTS.catalog,
          index: itemIndex,
        });
        itemIndex++;
      }

      this.$gtm.push({
        event: GTM_EVENTS.list.viewItem,
        item_list_name: ITEM_LISTS.catalog,
        ...getEcommerceItems(formattedProducts),
      });
    },

    async onUpdate(productCount, ids, {data: products, from, next_page_url}) {
      this.hasMore = next_page_url !== null;
      await this.pushViewItemListEvent(products, from);
    },

    onReset(prune = true) {
      if (prune) {
        this.metas = [];
      }
      this.loaded = false;
      this.selectedFilters = [];
      this.currentPage = 1;
      this.products = Array(20).fill({images : [DEFAULT_IMG]});
    },

    async fetchProducts() {
      const query = this.infiniteLoaderQuery = {...this.apiQueries};

      const products = await this.$api.$get(process.env.apiUrl + '/api/v2/search', {
        params: {
          page: this.currentPage,
          perPage: this.productPerPage,
          ...query
        }
      });


      if (products && products.data) {
        this.products = products.data;
        this.lastPage = products.last_page
        this.hasMore = products.next_page_url !== null;
        await this.pushViewItemListEvent(products.data, products.from);
      }

      this.loaded = true;
    },

    loadProducts: debounce(function (queries) {
      this.apiQueries = getApiQueries(this.$route.query, this.catalogs, this.brand);
      this.fetchProducts();
    }, 500),

    async refresh(loaded) {
      await this.$fetch()
      loaded('done')
    },
  }
}
</script>

<style lang="scss">
.listing-page {
  .brand-banner {
    img {
      width: 100%;
    }
  }
}
</style>
