import {brandSlug, capitalizeFirstLetter} from "assets/js/utils/string";
import {GTM_EVENTS} from "assets/js/gtm-events";
import {isObject} from "underscore";
import {getCatalogPath} from "assets/js/utils/catalog";

export const FILTERS_TYPES = {
  catalogs: 'catalogs',
  brands: 'brands',
  sizes: 'sizes',
  genders: 'genders',
  sellerGenres: 'seller_genres',
  colors: 'colors',
  states: 'states',
  priceMax: 'price_max',
  priceMin: 'price_min',
  sortBy: 'sort_by',
  metas: 'metas'
}

export const UNIQUE_FILTERS_TYPES = [
  FILTERS_TYPES.priceMax,
  FILTERS_TYPES.priceMin,
  FILTERS_TYPES.sortBy
]

export const ALL_ID = 'all'

const flatten = (a, property, keepParent = false) => {

  return a.reduce((old, next) => {
    let a = [...old];
    if (next[property]) {
      a = [...a, ...next[property]];
    }
    if (next.children) {
      a = [...a, ...flatten(next.children, property, keepParent)];
    } else if (keepParent) {
      a = [...a, next]
    }
    return a;
  }, []);
}

const extractChildrenGender = (data, arrayGender) => {
  for (let i=0; i < data.length; i++) {
    const child = data[i]
    if(child?.gender) {
      arrayGender.push(child.gender)
    }

    if(child?.children) {
      extractChildrenGender(child.children, arrayGender)
    }
  }
}

const isGenderedCatalog = (catalog) => {
  if (catalog.children) {
    // PAGE: SHOW ALL (Each page contains obj "gender")
    const genderPages = []
    // GenderPages contains only gender id 1
    extractChildrenGender(catalog.children, genderPages)
    return genderPages.length > 0
  } else {
    // PAGE: DIRECT
   return catalog.gender !== 0
  }
}

const getCatalogChildrens = (catalog) => {
  let catalogs = [];
  catalog.children.forEach(child => {
    catalogs.push(child.id);
    if (child.children) {
      catalogs = [...catalogs, ...child.children.map(c => c.id)]
    }
  });
  return catalogs
}

const getCatalogSizes = (catalog, configSizes) => {
  let sizes = []
  let allSizes = []

  if (catalog.children) {
    sizes = flatten(catalog.children, FILTERS_TYPES.sizes);
    allSizes = [...sizes];

    // Remove duplicate sizes
    sizes = sizes.filter((item, index) => sizes.indexOf(item) === index);

    sizes = configSizes.filter(size => sizes.includes(size.id));
  } else if (catalog.sizes) {
    sizes = configSizes.filter(size => catalog.sizes.includes(size.id));

    allSizes = sizes.reduce((old, next) => {
      return [...old, ...next.children.map(child => ({
        ...child,
        type: FILTERS_TYPES.sizes
      }))];
    }, []);
  }

  sizes = sizes.map(size => {
    return {
      ...size,
      children: size.children.map(elt => ({...elt, type: FILTERS_TYPES.sizes}))
    }
  });

  return sizes.length ? sizes : allSizes

}

const getCatalogMetas = (catalog, configMetas) => {
  let metas = []

  if (catalog.children) {
    metas = flatten(catalog.children, 'metas');
    metas = configMetas ? configMetas.filter(meta => metas.includes(meta.id)) : [];
  } else if (catalog.metas) {
    metas = configMetas.filter(meta => catalog.metas.includes(meta.id));
  }

  metas = metas.map(meta => {
    return {
      ...meta,
      children: meta.children.map(elt => ({...elt, type: `${FILTERS_TYPES.metas}.${meta.id}`}))
    }
  });

  return metas;
}

const getCatalogTree = async ($route, $store, $i18n) => {
  const category = $route.path.replace('/' + $i18n.locale, '').replace(/(\/)/g, '');

  let catalogTree = [];

  if (category && category.indexOf('_') > -1) {
    catalogTree = await $store.dispatch('nav/searchBySlug', category);
  } else {
    catalogTree = await $store.dispatch('nav/searchByUrl', category);
  }

  return catalogTree
}

export const getSearchSeo = async (brand, catalog, $i18n, query) => {
  if (catalog) {
    return catalog.seo
  } else if (brand) {
    const brandName = brand.slug.split('-').join(" ");
    const seo = {};
    seo.metaTitle = $i18n.t('brand.title', {name: capitalizeFirstLetter(brandName)})
    seo.metaDesc = $i18n.t('brand.desc', {name: capitalizeFirstLetter(brandName)})
    seo.pageTitle = $i18n.t('brand.page.title', {name: capitalizeFirstLetter(brandName)})
    seo.pageDesc = $i18n.t('brand.page.desc', {name: capitalizeFirstLetter(brandName)})
    return seo
  } else if (typeof query.q === "string") {
    const seo = {};
    seo.metaTitle = $i18n.t('catalogs.search', {search: query.q})
    seo.metaDesc = $i18n.t('catalogs.search.description')
    seo.pageTitle = $i18n.t('catalogs.search', {search: query.q})
    seo.pageDesc = $i18n.t('catalogs.search.description')
    seo.seoDesc = $i18n.t('catalogs.search.seo');
    return seo
  }

  return {
    metaTitle: null,
    metaDesc: null
  }
}

export const mapCatalog = (list, $i18n) => {

  return list.map(elmt => {
    if (elmt.children?.length) {
      elmt.children = [
        {
          name: $i18n.t('navigation.all'),
          iconImage: process.env.staticAssets + '/site/catalogs/default_icons/all.png',
          slug: elmt.slug,
          url: elmt.url,
          isAll: true,
          id: elmt.id,
          type: FILTERS_TYPES.catalogs
        },
        ...elmt.children.map(elt => {
          if (elt.children?.length) {
            elt.children = mapCatalog(elt.children)
          }
          return {...elt, type: FILTERS_TYPES.catalogs}
        })
      ]
    }
      return {...elmt, type: FILTERS_TYPES.catalogs}



  })
}

export const buildProps = async ($route, $i18n, $store, catalog) => {

  let canonical
  let catalogs = []

  if (catalog) {
    canonical = catalog.url;

    catalogs = [catalog.id];
    if (catalog.children_ids) {
      catalogs = catalog.children_ids
    }
  }

  return {
    canonical,
    catalogs
  };
}
export const buildAsyncMetas = async ($route, $i18n, $store, config) => {

  let catalogTree

  let sizes = []
  let metas = []
  let hasGender = true

  catalogTree = await getCatalogTree($route, $store, $i18n)

  if (catalogTree.length) {

    const catalog = catalogTree[catalogTree.length - 1];
    hasGender = isGenderedCatalog(catalog)

    if (catalog) {
      if (catalog.depth === 3) {
        metas = getCatalogMetas(catalog, config.metas)
      }

      sizes = getCatalogSizes(catalog, config.sizes)
    }
  } else {
    sizes = config.sizes ? config.sizes.reduce((old, next) => {
      return [...old, {
        ...next,
        children: next.children.map(child => ({...child, type: FILTERS_TYPES.sizes}))
      }];
    }, []) : [];
  }

  return {
    metas,
    catalogTree,
    sizes,
    hasGender
  };
}
export const buildBread = async ($route, catalog, $i18n, brand) => {
  const isSearch = $route.params.pathMatch === 'equipement';

  let bread = []
  if (catalog) {
    bread = getCatalogPath(catalog)
  }

  if (isSearch) {
    bread.push({
      name: $i18n.t('catalogs.search.title'),
      url: $route.fullPath
    });
  }

  if (brand) {
    bread.push({
      name: brandSlug($route.params.slug, $i18n),
      url: $route.fullPath
    });
  }

  return bread;
}

export const queryToFilters = (query, config, filtersList) => {
  let filters = [];
  Object.keys(query).forEach(key => {
    const list = filtersList[key]?.reducedList;
    if (list && key !== FILTERS_TYPES.metas) {
      if (UNIQUE_FILTERS_TYPES.includes(key)) {
        const value = list.find(filter => query[key] === filter.id);
        const filter = value ? formatUniqueFilter(key, value.id, value.name) : null

        if (filter?.value?.length) {
          filters = [...filters, filter.value[0]];
        }
      } else {
        const values = query[key].split(',').map(param => !isNaN(parseInt(param)) ? parseInt(param) : param);

        if (list.some(item => item.children?.length)) {
          const flattenList = flatten(list, FILTERS_TYPES.catalogs, true);
          filters = [...filters, ...flattenList.filter(filter => values.includes(filter.id))];
        } else {
          filters = [...filters, ...list.filter(filter => values.includes(filter.id))];
        }
      }
    } else if (key.indexOf(FILTERS_TYPES.metas) > -1) {
      const values = query[key].split(',').map(param => !isNaN(parseInt(param)) ? parseInt(param) : param);
      const metas = [];
      values.forEach(meta => {
        let found = null;
        config.metas.some(item => {
          found = item.children.find(child => child.id === meta);
          return found;
        });
        if (found) {
          found.type = key;
          metas.push(found);
        }
      });
      filters = [...filters, ...metas];
    } else if (UNIQUE_FILTERS_TYPES.includes(key)) {
      const filter = formatUniqueFilter(key, query[key])

      if (filter?.value?.length) {
        filters = [...filters, filter.value[0]];
      }
    }
  });
  return filters;
}

export const filtersToQueryObject = (filters, currentQuery, routeName) => {
  return filters.reduce((previous, next) => {
    const isBrandRoute = routeName.indexOf('brand-slug___') === 0

    if ((next.type !== 'brands' && isBrandRoute) || !isBrandRoute) {
      if (UNIQUE_FILTERS_TYPES.includes(next.type)) {
        previous[next.type] = next.id
      } else {
        previous[next.type] = previous[next.type] ? previous[next.type] + ',' + next.id : next.id.toString();
      }

    }

    return previous;
  }, {...currentQuery.q && {q: currentQuery.q}});
}

export const getApiQueries = (query, catalogs, brand) => {
  if (brand && brand.id) {
    query.brands = `${brand.id}`;
  }
  return Object.keys(query).reduce((accumulator, filterKey) => {
    if (filterKey !== 'q' && filterKey !== 'page') {
      if (UNIQUE_FILTERS_TYPES.includes(filterKey)) {
        return {
          ...accumulator,
          [filterKey]: query[filterKey]
        }

      } if (filterKey.indexOf(FILTERS_TYPES.metas) > -1) {
        if (!accumulator[FILTERS_TYPES.metas]) {
          accumulator[FILTERS_TYPES.metas] = []
        }
        accumulator[FILTERS_TYPES.metas].push(query[filterKey].split(',').map(id => !isNaN(parseInt(id)) ? parseInt(id) : id))
      } else {
        accumulator[filterKey] = query[filterKey].split(',').map(id => !isNaN(parseInt(id)) ? parseInt(id) : id);
      }
    }

    return accumulator;
  }, {...query.q && {q: query.q}, ...(catalogs && catalogs.length) && {catalogs}})
}
export const formatUniqueFilter = (name, value, label) => {
  const filter = []
  if (value) {
    filter.push({
      name: name,
      label: label,
      id: value,
      type: name,
    })
  }
  return {
    type: name,
    value: filter
  }
}

export const applyFilters = async (currentFilters, {value, remove, type}, $gtm, $store) => {

  if (remove) {
    currentFilters = currentFilters.filter(c => c.id + c.name !== value.id + value.name);
  } else if (value.length) {
    let temp = [...currentFilters];
    let filterToRemove = temp.filter(c => c.type === type && !value.find(elt => elt.id + elt.name === c.id + c.name));
    value.forEach((v, index) => {
      if (!temp.find(elt => elt.id + elt.name === v.id + v.name)) {
        temp.push(v);
      }
    });
    currentFilters = temp.filter(item => item.type !== type || !filterToRemove.find(elt => elt.id + elt.name === item.id + item.name));

    $gtm.push({
      event: GTM_EVENTS.list.filter,
      filter: {
        name: type,
        value: value.map(item => item.name),
      }
    })
  } else {
    // Empty array
    currentFilters = currentFilters.filter(c => c.type !== type);
  }
  return currentFilters
}
