<template>
    <DynamicRecycleView
      ref="scroller"
      v-if="$screen.mobile"
      :items="productsRows"
      :min-item-size="20"
      class="scroller product-scroller relative z-[1]"
      :finished="!hasMore"
      :is-sticky-header="true"
      :sticky-top-distance="stickyTopDistance"
      @onScroll="onScroll"
      :on-refresh="refresh"
      :on-loadmore="more">
      <template #beforeHeader>
        <slot name="beforeHeader"></slot>
      </template>
      <template #header>
        <slot name="header"></slot>
      </template>
      <template #before>
        <slot name="before"></slot>
      </template>
      <template v-slot="{ item, index, active }">
        <DynamicRecycleViewItem :item="item" :active="active" :data-index="index" class="px-8 content">
          <ProductRow
            :rowId="index"
            :gtm-item-list-name="gtmItemListName"
            :products="item.products"
            :owner="owner"
            :hide-product-header="hideProductHeader"
            @onClickProduct="onClickProduct"/>
        </DynamicRecycleViewItem>
      </template>
      <template #after>
        <slot name="footer"></slot>
      </template>
    </DynamicRecycleView>
    <div v-else>
      <slot name="beforeHeader"></slot>

      <slot name="header"></slot>

      <Pagination
        :loading="loading"
        :current-page="currentPage"
        @change="$emit('onPage', $event)"
        :last-page="lastPage">

        <template v-if="$slots.before && currentPage === 1">
          <div :class="productsContainerClasses">
            <Product
              v-for="(product, index) in firstProductsBlock"
              :product="product"
              :key="product.slug"
              :gtm-item-list-name="gtmItemListName"
              :index="index + 1"
              :hide-header="hideProductHeader"
              :owner="owner"
            />
          </div>

          <div class="my-20">
            <slot name="before"></slot>
          </div>

          <div :class="productsContainerClasses">
            <Product
              v-for="(product, index) in restProductsBlock"
              :product="product"
              :key="product.slug"
              :gtm-item-list-name="gtmItemListName"
              :index="index + firstProductsBlock.length + 1"
              :hide-header="hideProductHeader"
              :owner="owner"
            />
          </div>
        </template>

        <div v-else :class="productsContainerClasses">
          <Product
            v-for="(product, index) in products"
            :product="product"
            :key="product.slug"
            :gtm-item-list-name="gtmItemListName"
            :index="index + 1"
            :hide-header="hideProductHeader"
            :owner="owner"
          />
        </div>

      </Pagination>
      <slot name="footer"></slot>
    </div>
</template>

<script>
import Product from "@/components/Product"
import Loader from "~/assets/svg/loader.svg?inline";
import ProductRow from "@/components/product/ProductRow"
 import DynamicRecycleView from "@/components/RecycleView/DynamicRecycleView"
import DynamicRecycleViewItem from "@/components/RecycleView/DynamicRecycleViewItem"
import {ITEM_LISTS} from "assets/js/gtm-events";
import Loadmore from "@/components/Loadmore.vue"
import Pagination from "@/components/pagination/Pagination.vue";

export default {
  components: {Pagination, Loadmore, DynamicRecycleView, DynamicRecycleViewItem, ProductRow, Product, Loader},
  props: {
    query: {
      type: Object,
      default: () => {
        return {}
      }
    },
    stickyTopDistance: {
      type: String,
      default: 'top-0'
    },
    owner: {
      type: Boolean,
      default: false
    },
    moreActive: {
      type: Boolean,
      default: false
    },
    api: {
      type: String
    },
    startAt: {
      type: Number,
      default: 1
    },
    lastPage: Number,
    currentPage: Number,
    perPage: {
      type: Number,
      default: 40
    },
    slotRowPosition: {
      type: Number,
      default: 2,
    },
    initialProducts: {
      type: Array,
      default: []
    },
    perRow: {
      type: Number,
      default: 4
    },
    hideProductHeader: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    gtmItemListKey: {
      type: String,
      default: 'catalog',
      validator: (value) => Object.keys(ITEM_LISTS).includes(value),
    }
  },
  data() {
    return {
      nextPage: this.startAt || 1,
      products: [...this.initialProducts],
      productsRows: this.productsToRows([...this.initialProducts]),
      isFirstTime: true,
      loading: false,
      hasMore: this.moreActive,
      rowAnchor: 0,
    }
  },
  activated() {
    if (this.$screen.mobile) {
      this.$refs.scroller.scrollToItem(this.rowAnchor)
    }
  },
  computed: {
    gtmItemListName() {
      return ITEM_LISTS[this.gtmItemListKey];
    },
    productsContainerClasses() {
      return {
        'grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-14': true,
        'grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-3': this.perRow === 3,
        'grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-4': this.perRow === 4,
      }
    },
    firstProductsBlock() {
      // Use this.perRow instead of `4` when it will affect CSS directly
      return [...this.products].slice(0, this.slotRowPosition * 4);
    },
    restProductsBlock() {
      return this.firstProductsBlock.length > 0
        // Use this.perRow instead of `4` when it will affect CSS directly
        ? [...this.products].slice(this.slotRowPosition * 4, this.products.length)
        : [];
    }
  },
  watch: {
    moreActive(value) {
      this.hasMore = value;
    },
    products(value) {
      this.productsRows = this.productsToRows([...value])
    },
    initialProducts(value) {
      this.products = [...value];
    },
    currentPage(value) {
      this.fetchProducts(value)
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: value
        }
      })
    },
  },
  methods: {
    onScroll(value) {
      this.$emit('onScroll', value)
    },
    onClickProduct(rowId) {
      this.rowAnchor = rowId
    },
    async fetchProducts(page) {
      const products = await this.$api.$get(process.env.apiUrl + this.api, {
        params: {
          page: page,
          perPage: this.perPage,
          ...this.params,
          ...this.query
        }
      });


      if (!products.error) {
        this.products = products.data;

        if (products.data && products.data.length === 0) {
          this.$emit('empty');
        }

        this.$emit('loaded', true);

        if (products && products.data) {
          this.$emit(
            'update',
            products.data.length,
            products.data.map(product => {
              const slug = product.slug?.split('-')[0] ?? product.slug2.split('-')[0]
              return slug.split('-')[0];
            }),
            products);
        }

        this.loading = false;
      }
    },
    async more(callback) {
      this.$emit('loaded', false);
      this.loading = true;
      const products = await this.$api.$get(process.env.apiUrl + this.api, {
        params: {
          page: this.nextPage,
          perPage: this.perPage,
          ...this.params,
          ...this.query
        }
      });

      if (!products.error) {

        const lastProducts = this.products.length ? this.products[this.products.length - 1] : null;
        let start = 0;

        if (lastProducts) {
          for (let i = 0; i < products.data.length; i++) {
            if (products.data[i].slug === lastProducts.slug && i !== products.data.length - 1) {
              start = i + 1;
              break;
            }
          }
        }
        this.products = [...this.products, ...products.data.slice(start)];

        if (products.data && products.data.length === 0) {
          this.$emit('empty');
        }

        this.nextPage++;

        callback()
        this.$emit('loaded', true);

        if (products && products.data) {
          this.$emit(
            'update',
            products.data.length,
            products.data.map(product => {
              const slug = product.slug?.split('-')[0] ?? product.slug2.split('-')[0]
              return slug.split('-')[0];
            }),
            products);
        }

        this.loading = false;
      }
    },
    productsToRows(products) {
      const productsLength = products.length

      let rowsLength = Math.floor(productsLength / this.perRow)

      const remainingProducts = productsLength % this.perRow



      const productRows = [];

      if (!!(remainingProducts)) {
        productRows.push({
          id: 0,
          products: products.splice(-remainingProducts)
        })
      }

      for(let i = rowsLength; i > 0; i--) {
        productRows.push({
          id: i,
          products: products.splice(-this.perRow)
        })
      }


      return productRows.reverse()
    },
    refresh(loaded) {
      this.nextPage = 2
      this.$emit('refresh', loaded)
    }
  }
}
</script>
