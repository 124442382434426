<template>
    <div class="pb-footer-safe md:pb-4">
      <div class="content px-8">
        <slot></slot>
      </div>
      <div v-if="lastPage > 0" class="flex justify-center items-center font-heading text-16 font-medium  align-center py-6">
        <div class="pr-6 w-24">
          <IconButton v-if="currentPage > 1" key="paginationPrev" icon="arrow-left" @click.native.prevent="onPage(currentPage - 1)" small plain border />
        </div>

        <span>Page {{ currentPage }} sur {{ lastPage }}</span>
        <div class="pl-6 w-24">
          <IconButton v-if="currentPage < lastPage" key="paginationNext" icon="arrow" small plain border @click.native.prevent="onPage(currentPage + 1)" />
        </div>
      </div>
    </div>
</template>

<script>
import Loader from "~/assets/svg/loader.svg?inline";
import IconButton from "@/components/buttons/IconButton.vue";

const PAGE_NUMBER = 6;
const PAGE_DELIMITER = 4;

export default {
  components: {
    IconButton,
    Loader
  },
  model: {
    prop: 'currentPage',
    event: 'change'
  },
  props: {
    loading: Boolean,
    currentPage: Number,
    lastPage: Number,
  },
  computed: {
    allPages() {
      return Array.from({length: this.lastPage}, (_, i) => i + 1)
    },
    pages() {
      return this.allPages.filter((page) => {
        if (this.currentPage <= PAGE_DELIMITER) {
          return page <= PAGE_NUMBER
        }
        if (this.currentPage >= this.lastPage - PAGE_DELIMITER) {
          return page > this.lastPage - PAGE_NUMBER
        }
        return page > this.currentPage - PAGE_DELIMITER && page < this.currentPage + PAGE_DELIMITER
      })
    }
  },
  methods: {
    onPage(page) {
      if (this.$screen.mobile) {
        this.$el.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      } else {
        window.scrollTo({top: 0, behavior: 'smooth'})
      }

      this.$emit('change', page)
    }
  }
}
</script>
